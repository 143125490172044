import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import expensesReducer from './slices/expensesSlice';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        expenses: expensesReducer
    },
    middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

export default store;
