import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchExpensesRequest, fetchExpensesSuccess, fetchExpensesFailure } from '../slices/expensesSlice';
import { fetchExpensesApi } from '../api';

function* fetchExpenses() {
    try {
        const expenses = yield call(fetchExpensesApi);
        yield put(fetchExpensesSuccess(expenses));
    } catch (error) {
        yield put(fetchExpensesFailure(error.toString()));
    }
}

export default function* watchFetchExpenses() {
    yield takeLatest(fetchExpensesRequest.type, fetchExpenses);
}
