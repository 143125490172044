import React, { useState } from "react";
import QRCode from "qrcode";
import styles from "./style.module.scss";

function onQRTextChange(qrContent) {
  let qrUrl;
  QRCode.toString(qrContent, { type: "terminal" }, function (err, url) {
    qrUrl = url;
  });
  return qrUrl;
}

export default function () {
  const [qrText, setQRText] = useState("");
  const [qrImage, setQRImage] = useState(onQRTextChange("sample text"));

  return (
    <div className={styles.qrContainer}>
      <div className={styles.qrSearch}>
        <input
          type="text"
          value={qrText}
          placeholder="Please enter Text/URL to convert to QR Code"
          onChange={(qrContent) => {
            let value = qrContent.target.value;
            setQRText(value);
            setQRImage(onQRTextChange(value || "sample text"));
          }}
        />
      </div>

      <h2 style={{ margin: "20px 0 0 25px" }}>Easy and Fast QR Code Generation</h2>

      {qrImage && (
        <div
          className={styles.qrCodes}
          dangerouslySetInnerHTML={{ __html: qrImage }}
        />
      )}

      <QRNotes />
    </div>
  );
}

function QRNotes() {
  return (
    <div style={{ marginTop: 40 }}>
      <div style={{ marginLeft: 25, marginTop: 20 }}>What is QR Code?</div>
      <ul className={styles.list}>
        <li>
          <strong>Smart Information Square:</strong> A QR code is like a smart square
          filled with small black squares. It's not just any square; it's a
          quick way to store information.
        </li>
        <li>
          <strong>Phone Camera Magic:</strong> Think of your smartphone camera as a magic
          eye. When you point it at a QR code, it can read the information
          stored inside, like scanning a secret message.
        </li>
        <li>
          <strong>Info on the Go:</strong> QR codes are handy for carrying all sorts of
          info. It could be a website link, a coupon, or even contact details.
          You just scan, and voila, the info is yours!
        </li>
        <li>
          <strong>Everywhere Around You:</strong> You might have seen QR codes on posters,
          business cards, or even in your favorite restaurant's menu. They're
          everywhere because they make getting information quick and easy.
        </li>
        <li>
          <strong>SEO Friendly - QR Code Generator:</strong> If you're looking to create
          your own QR code, you'd want a "QR code generator." It's like a
          digital magic wand to make your own QR codes. So, when you search
          online for a way to make one, just type in "QR code generator," and
          you'll find tools to create your own special squares.
        </li>
      </ul>
    </div>
  );
}
