// rootSaga.js

import { all, fork } from 'redux-saga/effects';

import expenseSaga from './expenseSaga'; // Assuming you have this

function* rootSaga() {
    yield all([
        fork(expenseSaga),
        // Add other sagas here, forked.
    ]);
}

export default rootSaga;
