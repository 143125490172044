import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, redirect } from "react-router-dom";
import Navbar from "./components/NavBar";
import Home from "./components/Home";
import "./global.scss";

function Redirect() {
  let location = useLocation();

  useEffect(() => {
    if (location.path !== "qr-code-generator") {
      redirect("/qr-code-generator")
    }
  }, [location]);

  return null;
}

function App() {

  return (
    <Router>
      <Navbar />
      <Routes>
      <Route path="/" exact element={<Home/>} />
        <Route path="/qr-code-generator" exact element={<Home/>} />
        <Route path="/*" exact element={<Redirect/>} />
      </Routes>
    </Router>
  );
}

export default App;
