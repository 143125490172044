import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    expenses: [],
    loading: false,
    error: null
};

const expensesSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        fetchExpensesRequest: state => {
            state.loading = true;
            state.error = null;
        },
        fetchExpensesSuccess: (state, action) => {
            state.loading = false;
            state.expenses = action.payload;
        },
        fetchExpensesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchExpensesRequest, fetchExpensesSuccess, fetchExpensesFailure } = expensesSlice.actions;
export default expensesSlice.reducer;
