import axios from 'axios';

const api = axios.create({
    baseURL: 'YOUR_API_ENDPOINT',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default api;

export const fetchExpensesApi = async () => {
    try {
        const response = await api.get('/expenses');
        return response.data;
    } catch (error) {
        throw error;
    }
};
