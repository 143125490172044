import React from 'react';
import styles from "./style.module.scss"

function Navbar() {
    return (
        <div className={styles.navbar}>
            <h1>Create Custom QR Codes Online</h1>
        </div>
    );
}

export default Navbar;